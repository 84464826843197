import {
  ExclamationIcon,
  XCircleIcon,
  CheckCircleIcon
} from '@heroicons/react/solid'
import cn from 'classnames'

type Props = {
  title: string
  message: string
  type: 'warn' | 'error' | 'success'
  className: string
}

export default function Notice({ title, message, type, className }: Props) {
  let Icon: typeof ExclamationIcon
  switch (type) {
    case 'error': {
      Icon = XCircleIcon
      break
    }
    case 'warn': {
      Icon = ExclamationIcon
      break
    }
    case 'success': {
      Icon = CheckCircleIcon
      break
    }
    default: {
      Icon = CheckCircleIcon
    }
  }
  return (
    <div className={cn('rounded-md bg-yellow-50 p-4', className)}>
      <div className="flex">
        <div className="flex-shrink-0">
          <Icon aria-hidden="true" className="h-5 w-5 text-yellow-400" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-yellow-800">{title}</h3>
          <div className="mt-2 text-sm text-yellow-700">
            <p>{message}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

import { withApollo } from 'components/withApollo'
import PublicLayout from 'components/public/PublicLayout'
import React from 'react'
import { AuthMethod, useAuth } from 'components/AuthProvider'
import { useRouter } from 'next/router'
import useToast from 'hooks/useToast'
import rollbar from 'utils/rollbar'
import { flashUrl } from 'components/Flash'

const GoogleIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
      fill="#FFC107"
    />
    <path
      d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
      fill="#FF3D00"
    />
    <path
      d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
      fill="#4CAF50"
    />
    <path
      d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
      fill="#1976D2"
    />
  </svg>
)

const GithubIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" {...props}>
    <path
      clipRule="evenodd"
      d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
      fillRule="evenodd"
    />
  </svg>
)

const GoogleLoginButton = () => {
  const { startLogin } = useAuth()
  const router = useRouter()
  const toast = useToast()
  const onStartLogin = async (method: AuthMethod) => {
    try {
      const operator = await startLogin(method)
      if (operator) {
        return router.push('/dashboard')
      }
      return router.push(
        `/signup?${flashUrl({
          type: 'warn',
          message: "You don't yet have an account, please signup first",
          title: 'Account Not Found'
        })}`
      )
    } catch (e: any) {
      toast({
        title: 'Error',
        message: e.message,
        variant: 'error'
      })
      rollbar.warn(e)
      return null
    }
  }
  return (
    <button
      className="inline-flex items-center px-6 py-3 shadow-sm text-base font-medium rounded-md text-black bg-gradient-to-r from-pink-100 to-purple-300 hover:from-pink-400 hover:to-purple-300  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 flex justify-center"
      onClick={() => onStartLogin('google')}
      type="button"
    >
      <GoogleIcon className="-ml-1 mr-3 h-5 w-5" />
      Sign in with Google
    </button>
  )
}
const GithubLoginButton = () => {
  const { startLogin } = useAuth()
  const router = useRouter()
  const toast = useToast()
  const onStartLogin = async (method: AuthMethod) => {
    try {
      const operator = await startLogin(method)
      if (operator) {
        return router.push('/dashboard')
      }
      return router.push(
        `/signup?${flashUrl({
          type: 'warn',
          message: "You don't yet have an account, please signup first",
          title: 'Account Not Found'
        })}`
      )
    } catch (e: any) {
      toast({
        title: 'Error',
        message: e.message,
        variant: 'error'
      })
      rollbar.warn(e)
      return null
    }
  }
  return (
    <button
      className="inline-flex items-center px-6 py-3 shadow-sm text-base font-medium rounded-md text-black bg-gradient-to-r from-pink-100 to-purple-300 hover:from-pink-400 hover:to-purple-300  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 flex justify-center"
      onClick={() => onStartLogin('github')}
      type="button"
    >
      <GithubIcon className="-ml-1 mr-3 h-5 w-5" />
      Sign in with GitHub
    </button>
  )
}

const LoginPage = () => {
  return (
    <PublicLayout footer={false} header={false} isStatic>
      <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            alt="Workflow"
            className="mx-auto h-12 w-auto"
            src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Sign in to your account
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Or{' '}
            <a
              className="font-medium text-indigo-600 hover:text-indigo-500"
              href="/signup"
            >
              start for free
            </a>
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 flex flex-col gap-10">
            <GoogleLoginButton />
            <GithubLoginButton />
          </div>
        </div>
      </div>
    </PublicLayout>
  )
}

export default withApollo({ ssr: false })(LoginPage)
